//https://my.matterport.com/show/?m=3abEibxujEn&play=1&qs=1&hr=0&help=0&guides=0&mls=2&tourcta=0&f=0&ts=1
import React from "react"
import "./home-background.scss"

class HomeBackground extends React.Component {
  render() {
    // const propertyPriceDesc = (
    //   <Text
    //     text='Casa Azul Profundo'
    //     font='font_style__field'
    //     color='color__white'
    //   ></Text>
    // );
    // const propertyPriceValue = (
    //   <Text
    //     text='Walled City, Cartagena - Colombia'
    //     color='color__white'
    //   ></Text>
    // );

    return (
      <div className="home_background home_background__image">
        {/* <div className='home_background__container'>
          <TextGroup
            className='home_background__content'
            firstLine={propertyPriceDesc}
            secondLine={propertyPriceValue}
          ></TextGroup>
          <a
            className='home_background__link'
            href='/property/Boca-de-Tomatlan/Villa-Armonia-5c7e0ad3669eb20f9c51dc71'
          >
            <Text icon='keyboard_arrow_right' color='color__white'></Text>
          </a>
        </div> */}
      </div>
    )
  }
}

export default HomeBackground
