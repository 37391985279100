import React, { useState } from "react"
import { Helmet } from "react-helmet"
import "../translations/i18n"

import NavBar from "../components/organisms/navbar"
import HomeBackground from "../components/organisms/home-background"
import Modal from "../components/atoms/modal"
import ContactForm from "../components/organisms/contact-form"
import ListHomeForm from "../components/organisms/list-home-form"

import "../assets/css/index.scss"

function NotFound(props) {
  // Init states for list modal
  const [listState, setListState] = useState(false)
  const showListModal = () => setListState(true)
  const hideListModal = () => setListState(false)

  // Init states for contact modal
  const [contactState, setContactState] = useState(false)
  const showContactModal = () => setContactState(true)
  const hideContactModal = () => setContactState(false)

  // Set the links to open a form in a modal
  const link = [
    { id: "listHome", modal: showListModal },
    { id: "contactUs", modal: showContactModal },
  ]

  return (
    <React.Fragment>
      <Helmet>
        <title>
          Navigaze. Vacation Rentals. Punta Mita & Puerto Vallarta Mexico.
        </title>
        <meta
          name="title"
          content={`Navigaze. Vacation Rentals. Punta Mita & Puerto Vallarta Mexico.`}
        />
        <meta
          name="description"
          content="Find your dream holiday. Explore cities & locations in 360 degrees. All properties have virtual tours. No surprises. No extra fees."
        />
      </Helmet>
      <NavBar links={link} />
      <HomeBackground />
      <Modal
        classId="contact_form_modal"
        show={contactState}
        children={
          contactState ? (
            <ContactForm handleClose={() => hideContactModal()} />
          ) : (
            ""
          )
        }
        handleClose={() => hideContactModal()}
      />
      <Modal
        classId="list_home_form_modal"
        show={listState}
        children={
          listState ? <ListHomeForm handleClose={() => hideListModal()} /> : ""
        }
        handleClose={() => hideListModal()}
      />
    </React.Fragment>
  )
}
export default NotFound
